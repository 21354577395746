<template>
    <el-scrollbar height="100vh" width="100vw">
        <div id="div-max">
            <div id="div-first">
                <FirstView/>
            </div>
            <div id="div-intro">
                <IntroView/>
            </div>
            <div id="div-develop">
                <DevelopView/>
            </div>
            <div id="div-coreView">
                <CoreView/>
            </div>
            <div id="div-area">
                <AreaView/>
            </div>
            <div id="div-aptitude">
                <AptitudeView/>
            </div>
<!--            <div id="div-case">
                <CaseView v-for="(item,index) in caseDataList" :dataVal="item" :key="index"/>
            </div>
            <div id="div-contact">
                <ContactView/>
            </div>-->
            <div id="div-icp">
                <IcpView/>
            </div>
        </div>
    </el-scrollbar>
</template>
<script setup>
import FirstView from "@/components/content/FirstView.vue";
import IntroView from "@/components/content/IntroView.vue";
import DevelopView from "@/components/content/DevelopView.vue";
import CoreView from "@/components/content/CoreView.vue";
import AreaView from "@/components/content/AreaView.vue";
import AptitudeView from "@/components/content/AptitudeView.vue";
/*import CaseView from "@/components/content/CaseView.vue";
import ContactView from "@/components/content/ContactView.vue";*/
import IcpView from "@/components/content/IcpView.vue";
import emitter from "@/assets/js/mitt";
import {onMounted, onUnmounted} from "vue";

function onMenuClick(index) {
    console.log(index);
    let mdId = "";
    switch (index) {
        case 0:
            mdId = "#div-first";
            break
        case 1:
            mdId = "#div-intro";
            break
        case 2:
            mdId = "#div-develop";
            break
        case 3:
            mdId = "#div-coreView";
            break
        case 4:
            mdId = "#div-area";
            break
        case 5:
            mdId = "#div-aptitude";
            break
        case 6:
            mdId = "#div-case";
            break
        case 7:
            mdId = "#div-contact";
            break
        default:
            mdId = "#div-first";
            break
    }
    document
        .querySelector(mdId)
        .scrollIntoView({block: "start", behavior: "smooth"});
}

onMounted(() => {
    emitter.on("onMenuClick", (index) => {
        onMenuClick(index);
    });
});

onUnmounted(() => {
    emitter.off("onMenuClick");
});

/*let caseDataList = ref([
    {
        numTitle:"案例1",
        title:"7-ELEVEn",
        desList:[
            "7-ELEVEn 7-ELEVEn 不是一种传统的便利店形式，而是 集团线下的一家创新的\n" +
            "智能商店。它是新路业务部建立的一家创新的智能商店，它遵循无界零售理念。\n" +
            "3500除了提供高质量的商品外，还将出口品牌、型号和管理，在\"认识人、了解商\n" +
            "品、了解领域\"的基础上，重建零售的成本、效率和经验。",
            "7-ELEVEn是小店的升级品牌、供应链、经营、科技创新等能力可以赋予小商\n" +
            "店，并通过提供模块化的现场操作服务，使店主能够根据消费场景的需要做出不同\n" +
            "的选择，从而实现商店在类别、形象和管理能力上的提升。"
        ],
        bottomDes: "落地情况：7-Eleven便利店（广东区域320个门店安装及日常维护）",
        imgBig:require("@/assets/imgs/case/anli1.png"),
        imgList:[
            require("@/assets/imgs/case/anli1.1.png"),
            require("@/assets/imgs/case/anli1.2.png")
        ]
    },
    {
        numTitle:"案例2",
        title:"美宜佳便利店",
        desList:[
            "美宜佳，成立于1997年，系东莞市糖酒集团旗下商业流通企业，也是中国规模领先的连锁便利店品牌。" +
            "作为便利店行业先驱，美宜佳坚持与所有伙伴平台共创、资源共生、成果共赢、价值共享，" +
            "以优质的商品、贴心的服务，为万千家庭提供便利生活，成为人们生活中更亲近的伙伴。",
            "截至2023年3月，美宜佳连锁店数已超31000家，月均服务顾客超2亿人次，覆盖全国20多个省 (自治区、直辖市) ，" +
            "为220多座城市消费者带来美好便利生活，让奋斗者收获美满幸福人生。26年来优质贴心的服务，" +
            "获得社会及行业高度肯定，先后荣获中国零售业十大优秀特许加盟品牌、中国便利店大奖、中国特许奖等上百项荣誉。"
        ],
        bottomDes: "落地情况：美宜佳连锁门店（全国区域10000+门店安装及日常维护）",
        imgBig:require("@/assets/imgs/case/anli2.png"),
        imgList:[
            require("@/assets/imgs/case/anli2.1.png"),
            require("@/assets/imgs/case/anli2.2.png"),
            require("@/assets/imgs/case/anli2.3.png")
        ]
    },
    {
        numTitle:"案例3",
        title:"麦当劳",
        desList:[
          "麦当劳 (McDonald's) 是全球大型跨国连锁餐厅，麦当劳1955年创立于美国芝加哥，在世界上大约拥有3万间分店。" +
          "主要售卖汉堡包，以及薯条、炸鸡、汽水、冰品沙拉、水果等快餐食品。大多数麦当劳快餐厅都提供柜台式和得来速式，" +
          "即指不下车便可以用餐的一种快餐服务，顾客可以驾车在门口点餐，然后绕过餐厅，在出口处取餐.两种服务方式，同时提供室内就餐，有时也提供室外座位"
        ],
        bottomDes: "落地情况：麦当劳门店（东莞区域 230个门店日常运维服务，新店安装、旧店改造等）",
        imgBig:require("@/assets/imgs/case/anli3.png"),
        imgList:[
            require("@/assets/imgs/case/anli3.1.png"),
            require("@/assets/imgs/case/anli3.2.png")
        ]
    },
    {
        numTitle:"案例4",
        title:"良品铺子",
        desList:[
           "2006 年，第一家良品铺子出现在武汉街头。创业之初，良品铺子就决意「把全世\n" +
           "界的美味零食带到顾客家门口」。在实现品牌愿景的过程中，良品铺子不断沉淀积累、\n" +
           "创新突破，从最初的小店逐步蜕变为现今的平台级企业。",
            "2019年，在多样化、多场景、高品质、高频次的消费诉求驱动下，良品铺子将“高\n" +
            "端零食”确立为企业战略发展方向。良品铺子优选全球精品食材，匠心打造每款产品，既\n" +
            "重视健康与安全，也追求高颜值和好风味。"
        ],
        bottomDes: "落地情况：良品铺子门店（广东区域 370个门店安装，巡检及日常维护）",
        imgBig:require("@/assets/imgs/case/anli4.png"),
        imgList:[
            require("@/assets/imgs/case/anli4.1.png"),
            require("@/assets/imgs/case/anli4.2.png")
        ]
    },
    {
        numTitle:"案例5",
        title:"钱大妈",
        desList:[
           "钱大妈是中国社区生鲜连锁领军品牌，率先在行业内大规模推广“ 社区加盟\n" +
           "制” 商业模式，是目前珠三角区域最大的社区生鲜肉菜专卖集群，“ 不卖隔夜肉” \n" +
           "宣传语成了门店招牌，线下门店拓展速度快，目前全国门店数在 家。 3000 \n" +
           "2019 SUNMI X2 年开始搭载锥智科技定制的管理系统的 收银机应用于钱大妈门\n" +
           "店，收银简 单易上手，对供应链管控和人员管理有显著效果，极大地降低了生鲜\n" +
           "的损耗率。"
        ],
        bottomDes: "落地情况：钱大妈连锁门店（广东区域 300个门店安装及日常维护）",
        imgBig:require("@/assets/imgs/case/anli5.png"),
        imgList:[
            require("@/assets/imgs/case/anli5.1.png"),
            require("@/assets/imgs/case/anli5.2.png")
        ]
    },
    {
        numTitle:"案例6",
        title:"妍丽连锁门店",
        desList:[
           "AFIONA妍丽是妍丽化妆品 (中国) 有限公司旗下化妆品品牌，始创于1995年" +
           "，专注于为中国消费者提供来自全球各地化妆品与定制化的肌肤解决方案，" +
           "通过差异化的商品组合、肌肤管家的服务、覆盖全国的直营网络为用户带来服务体验。",
            "妍丽连锁门店覆盖全国56座城市。涵盖护肤、彩妆、香水、仪器等9大美妆品类，并在香港、海外均设有选品调研和买手合作团队。"
        ],
        bottomDes: "落地情况：妍丽连锁门店（全国区域 300个门店安装及日常维护） ",
        imgBig:require("@/assets/imgs/case/anli6.png"),
        imgList:[
            require("@/assets/imgs/case/anli6.1.png"),
            require("@/assets/imgs/case/anli6.2.png"),
            require("@/assets/imgs/case/anli6.3.png")
        ]
    },
    {
        numTitle:"案例7",
        title:"良品铺子",
        desList:[
           "“京东服务+”实现了行业内全的服务模式，包含了上门、取送以及到店三种模式，\n" +
           "而服务的范围涵盖了手机数码、电脑办公、家电、家居家纺等品类的安装维修、清洗\n" +
           "保养等服务，以及家政、保洁等居家服务，覆盖了37个实物品类和近6000个实物品\n" +
           "牌。截止2018年8月，已与200余家优质服务商达成合作，线下门店超过1万家，覆盖全\n" +
           "国 个区县，服务小哥10余万名。"
        ],
        bottomDes: "落地情况：广东省东莞市区域京东3c服务（月服务客户200人次） ",
        imgBig:require("@/assets/imgs/case/anli7.png"),
        imgList:[
            require("@/assets/imgs/case/anli7.1.png"),
            require("@/assets/imgs/case/anli7.2.png")
        ]
    },
    {
        numTitle:"案例8",
        title:"良品铺子",
        desList:[
          "广东超便利数字科技有限公司于2017年创办于东莞，团队拥有多年的连锁店管理和互联网工作经验，为了践行“为零售业赋能\"的企业愿景，" +
          "创业五年来围绕便利店、连锁药店、仓储分拣等零售场景和供应链场景去创新研发智能设备" +
          "，通过软硬件结合的智能产品及服务为零售业降本增效，为企业提升数字化运营能力。",
            "超便利所研发的取药宝智慧药店智能货架系列设备、迅龙医药仓储智能快速分拣系统、 " +
            "海卫思医院智慧药房发药机等智能设备及数字化产品，解决了当下企业高昂的物业租金成本、人力成本、经营效率等问题。"
        ],
        bottomDes: "落地情况：全国区域智能分拣设备安装部署及日常维",
        imgBig:require("@/assets/imgs/case/anli8.png"),
        imgList:[
            require("@/assets/imgs/case/anli8.1.png"),
            require("@/assets/imgs/case/anli8.2.png"),
            require("@/assets/imgs/case/anli8.3.png")
        ]
    }
]);*/

</script>

<style scoped>

#div-max {
    width: 100vw;
    min-height: 100vh;
    background-color: white;
}

</style>
