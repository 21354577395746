<template>
    <div id="div-root">
        <div id="div-left">
            <div id="div-left-kuai">1.5 公司资质</div>
        </div>
        <div id="div-right">
            <div id="div-content">
                <div id="div-zs">
                    <div id="div-title">
                        <label id="lb-1">公司资质</label>
                        <label id="lb-2">彰显坚实的技术能力</label>
                    </div>
                    <div id="div-img-1">
                        <div id="div-item" v-for="(item,index) in img1s" :key="index">
                            <img class="img" :src="item" alt=""/>
                        </div>
                    </div>
                    <div id="div-img-2">
                        <div id="div-item" v-for="(item,index) in img2s" :key="index">
                            <img class="img" :src="item" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {ref} from "vue";

let img1s = ref([
    require("@/assets/imgs/certificate/ic_zs_1.png"),
    require("@/assets/imgs/certificate/ic_zs_2.png"),
    require("@/assets/imgs/certificate/ic_zs_3.png")
]);

let img2s = ref([
    require("@/assets/imgs/certificate/ic_zs_4.png"),
    require("@/assets/imgs/certificate/ic_zs_5.png"),
    require("@/assets/imgs/certificate/ic_zs_6.png"),
    require("@/assets/imgs/certificate/ic_zs_7.png"),
    require("@/assets/imgs/certificate/ic_zs_8.png"),
    require("@/assets/imgs/certificate/ic_zs_9.png")
]);

</script>

<style scoped>
#div-root {
    width: 100vw;
    height: 100vh;
    background-color: #F5E9E9;
    display: flex;
    flex-direction: row;
    position: relative;
}

#div-left {
    width: 25vw;
    height: 100vh;
    padding-top: 40px;
    position: absolute;
    z-index: 10;
}

#div-left-kuai {
    width: 15vw;
    height: 16vh;
    background-color: #ED7D31;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vmin;
    color: white;
}

#div-right {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

#div-content {
    width: 80vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#div-zs {
    width: 75vw;
    height: 45vw;

}

#div-zs #div-title {
    width: 100%;
    height: 14.28%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFD966;
    color: black;
}

#div-zs #div-title #lb-1 {
    font-size: 3.5vmin;
    font-weight: bold;
}

#div-zs #div-title #lb-2 {
    font-size: 3vmin;
    font-weight: bold;
}

#div-zs #div-img-1 {
    width: 100%;
    height: 42.85%;
    margin-top: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#div-zs #div-img-1 #div-item {
    width: 33.3333%;
    height: 100%;
    background-color: #BFCAD9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1px;
}

#div-zs #div-img-1 #div-item img {
    width: 90%;
    height: 90%;
}

#div-zs #div-img-2 {
    width: 100%;
    height: 42.85%;
    margin-top: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#div-zs #div-img-2 #div-item {
    width: 16.6%;
    height: 100%;
    background-color: #DDE2E9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
}

#div-zs #div-img-2 #div-item img {
    width: 90%;
    height: 90%;
}

</style>