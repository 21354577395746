
import {createRouter, createWebHashHistory} from 'vue-router'

import MainView from "@/components/MainView.vue";

const routes=[
    {
        name: 'MainView',
        path: '/',
        component: MainView,
        children: [
            {
                name:'FirstView',
                path:'/FirstView',
                components:{
                    tabContent:() => import('@/components/content/FirstView.vue')
                }
            },
            {
                name:'IntroView',
                path:'/IntroView',
                components:{
                    tabContent:() => import('@/components/content/IntroView.vue')
                }
            },
            {
                name:'DevelopView',
                path:'/DevelopView',
                components:{
                    tabContent:() => import('@/components/content/DevelopView.vue')
                }
            },
            {
                name:'CoreView',
                path:'/CoreView',
                components:{
                    tabContent:() => import('@/components/content/CoreView.vue')
                }
            },
            {
                name:'AreaView',
                path:'/AreaView',
                components:{
                    tabContent:() => import('@/components/content/AreaView.vue')
                }
            },
            {
                name:'AptitudeView',
                path:'/AptitudeView',
                components:{
                    tabContent:() => import('@/components/content/AptitudeView.vue')
                }
            },
            {
                name:'CaseView',
                path:'/CaseView',
                components:{
                    tabContent:() => import('@/components/content/CaseView.vue')
                }
            },
            {
                name:'ContactView',
                path:'/ContactView',
                components:{
                    tabContent:() => import('@/components/content/ContactView.vue')
                }
            },
            {
                name:'IcpView',
                path:'/IcpView',
                components:{
                    tabContent:() => import('@/components/content/IcpView.vue')
                }
            }
        ]
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router

