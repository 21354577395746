<template>
    <div id="div-root">
        <div id="div-top">
            <img  id="div-top-img" src="@/assets/imgs/ic_top_bg.png" alt=""/>
            <div id="div-menu">
                <label class="menu-text" @click="onMenuClick(0)">首页</label>
                <label class="menu-text" @click="onMenuClick(1)">公司简介</label>
                <label class="menu-text" @click="onMenuClick(2)">发展历程</label>
                <label class="menu-text" @click="onMenuClick(3)">核心优势</label>
                <label class="menu-text" @click="onMenuClick(4)">覆盖区域</label>
                <label class="menu-text" @click="onMenuClick(5)">公司资质</label>
<!--                <label class="menu-text" @click="onMenuClick(6)">客戶案例</label>
                <label class="menu-text" @click="onMenuClick(7)">联系方式</label>-->
            </div>
        </div>
        <div id="div-content">
            <div id="div-img-logo">
                <img id="img-logo" src="@/assets/imgs/ic_logo.png" alt=""/>
            </div>
            <div id="div-img-first-bg">
                <img src="@/assets/imgs/ic_first_bg.png" alt=""/>
            </div>
        </div>
    </div>
</template>

<script setup>
import emitter from "@/assets/js/mitt";
  function onMenuClick(index){
      emitter.emit("onMenuClick", index);
  }

</script>

<style scoped>
#div-root {
    width: 100vw;
    height: 100vh;
}

#div-top{
    width: 100vw;
    height: 22vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#div-top-img {
    width: 100vw;
    height: 22vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}

#div-top-img img {
    width: 100vw;
    height: 22vh;
}

#div-menu{
    width:auto;
    height: 6vh;
    position: absolute;
    margin-top: 12vh;
    z-index:1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fc8931;
    padding-right: 42px;
    padding-left: 20px;
}

.menu-text{
    font-size: 2.8vmin;
    margin-left: 3vw;
    color: white;
}

.menu-text:hover {
    transition-duration: 0.3s;
    transition-property: box-shadow;
    box-shadow: 0 0 60px #F3A433;
    cursor: pointer;
    color: red;
}

#div-content {
    width: 100vw;
    height: 78vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#div-img-logo{
    width: 33vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#img-logo {
    width: 65vmin;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 26px;
    margin-bottom: 20vh;
}

#div-img-first-bg{
    width: 67vw;
    height: 78vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

#div-img-first-bg img{
    width: auto;
    height: 78vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}

</style>