<template>
    <div id="div-root">
        <div id="div-left">
            <div id="div-left-kuai">1.1 公司简介</div>
        </div>
        <div id="div-right">
            <div id="div-right-top">
                <label style="font-size: 4vmin;color: black">专业服务 行业领先</label>
                <div id="div-img-bg">
                    <img id="img-bg" src="@/assets/imgs/ic_jianjie_bg.png" alt=""/>
                </div>
            </div>
            <div id="div-right-bottom">
                <label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;维安信息成立于2017年，位于广东省东莞市南城区会展中心，公司拥有多个国内外知名品牌专业认证工程师，技术成员平均从事IT服务行业10年以上，以卓越的服务品质、专业安全的技术服务实力，为用户提供优质的IT服务。
                    维安信息专注于IT规划咨询、IT运维管理、系统集成及解决方案、IT基础架构的数字化转型等，形成了覆盖客户IT全生命周期的一站式服务解决方案。
                    维安信息客户涉及零售、金融、制造业、政府等行业客户，其技术团队成员有着为2008年奥运提供专业IT支持服务的经验和10年中国零售巨头美宜佳便利连锁店近万家门店的服务经验，凭借扎根行业15年的技术积累沉淀和丰富的行业服务经验、完善高效的服务付体系，助力客户降本增效、共同营造最佳服务体验。
                </label>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
#div-root {
    width: 100vw;
    height: 100vh;
    background-color: #f5e9e9;
    display: flex;
    flex-direction: row;
}

#div-left{
    width: 25vw;
    height: 100vh;
    padding-top: 40px;
    position: relative;
    z-index:10;
}

#div-left-kuai{
    width: 15vw;
    height: 16vh;
    background-color: #ED7D31;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:4vmin;
    color: white;
}

#div-right{
    width: 75vw;
    height: 100vh;
}

#div-right-top{
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

#div-right-top label{
    font-size: 6vmin;
    color: #000000;
    font-weight: bold;
}

#div-right-bottom{
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
}

#div-right-bottom label{
    width: 90%;
    font-size:3vmin ;
    margin-right: 20px;
}

#div-img-bg{
    width: 40vw;
    height: 38vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
}
#div-img-bg #img-bg{
    width: 30vw;
    height: 28vh;
}
</style>