<template>
    <div id="div-root">
        <div id="div-left">
            <div id="div-left-kuai">1.4 覆盖区域</div>
        </div>
        <div id="div-right">
            <div id="div-content">
                <div id="div-text">
                    <div id="div-text-area">
                        <label>服务已覆盖区域</label>
                        <div id="div-kuai"></div>
                    </div>
                    <label id="text-num">连锁门主占服务<br/>数 >10000+</label>
                </div>
                <div id="div-map">
                    <img  id="div-map-img" src="@/assets/imgs/ic_area.png" alt=""/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
#div-root {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    position: relative;
}

#div-left{
    width: 25vw;
    height: 100vh;
    padding-top: 40px;
    position: absolute;
    z-index:10;
}

#div-left-kuai{
    width: 15vw;
    height: 16vh;
    background-color: #ED7D31;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:4vmin;
    color: white;
}

#div-right{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

#div-content{
    width: 80vw;
    height: 100vh;
    position: relative;
}

#div-map{
    width: 80vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
}

#div-map-img{
    width: auto;
    height: 90vmin;
}

#div-text{
    width: 80vw;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    position: absolute;
    z-index: 2;
}
#div-text #div-text-area{
    font-size: 3vmin;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#div-text #div-text-area #div-kuai{
   width: 5vmin;
    height: 2.5vmin;
    background-color: #94de45;
    margin-left: 5px;
}

#div-text #text-num{
    font-size: 3vmin;
    color: black;
    margin-left: 24vw;
}

</style>