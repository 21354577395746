<template>
    <div id="div-root">
        <div id="div-content">
            <label class="txt">©2017-2024&nbsp;&nbsp;东莞市维安信息&nbsp;&nbsp;版权所有&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;粤ICP备2022040950号</label>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
#div-root {
    width: 100vw;
    height: 60px;
    background-color: #FC8931;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#div-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 1.5vmin;
}

</style>