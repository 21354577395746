<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

</script>

<style>
html, body, #app {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

html{font-size:62.5%;}
body {font:normal 100% Arial,sans-serif;
    font-size:1.4rem;}
</style>
