<template>
    <div id="div-root">
        <div id="div-left">
            <div id="div-left-kuai">1.3 核心优势</div>
        </div>
        <div id="div-right">
            <div id="div-content">
                <div class="div-item" v-for="(item ,index) in dataList" :key="index">
                    <div class="div-img">
                        <img class="img" :src="item.imgName" alt=""/>
                    </div>
                    <div class="div-text">
                        <label class="text-title">{{ item.title }}</label>
                        <label class="text-des">{{ item.des }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {ref} from "vue";

let dataList = ref([
    {
        imgName: require("@/assets/imgs/ic_core_1.png"),
        title: "覆盖全国的服务网络",
        des: "全国1-6级城市全覆盖，多个服务合作网点，7*24的即时响应，快速解决当地客户问题"
    },
    {
        imgName: require("@/assets/imgs/ic_core_2.png"),
        title: "丰富的工程师资源",
        des: "IBM、Lenovo、DELL品牌授权工程师认证，安防认证 ，品牌专业培训，专业技能千锤百炼"
    },
    {
        imgName: require("@/assets/imgs/ic_core_3.png"),
        title: "完整的服务业务管理系统",
        des: "自研发全流程IT服务运维管理系统，对接三大业务模板，海量工单大规模部署"
    },
    {
        imgName: require("@/assets/imgs/ic_core_4.png"),
        title: "全周期IT服务解决方案",
        des: "提供从IT规划、集成部署、运维保障、持续改进、产品生命周期管理的全周期服务"
    },
    {
        imgName: require("@/assets/imgs/ic_core_5.png"),
        title: "15年服务最佳交付实践",
        des: "15+年的实践，在技术、流程、工具、组织、资源、项目管理交付实践经验"
    }
]);

</script>

<style scoped>
#div-root {
    width: 100vw;
    height: 100vh;
    background-color: #F5E9E9;
    display: flex;
    flex-direction: row;
    position: relative;
}

#div-left {
    width: 25vw;
    height: 100vh;
    padding-top: 40px;
    position: absolute;
    z-index: 10;
}

#div-left-kuai {
    width: 15vw;
    height: 16vh;
    background-color: #ED7D31;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vmin;
    color: white;
}

#div-right {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

#div-content {
    height: 100vh;
    margin-left: 25vw;
    margin-top: 8vh;
}

.div-item {
    height: 14vh;
    display: flex;
    flex-direction: row;
    margin-bottom: 3vh;
}

.div-item .div-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.div-item .div-text .text-title {
    font-size: 4vmin;
    color: black;
}

.div-item .div-text .text-des {
    font-size: 3vmin;
    color: black;
    margin-top: 8px;
}

.div-img {
    width: auto;
    height: 12vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.img {
    width: auto;
    height: 12vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}

</style>