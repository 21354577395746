<template>
    <div id="div-root">
        <div id="div-left">
            <div id="div-left-kuai">1.2 发展历程</div>
        </div>
        <div id="div-right">
            <div id="div-bottom">
                <img id="img-bg" src="@/assets/imgs/ic_develop.png" alt=""/>
            </div>
            <div id="div-top">
                <label>至2023年3月，公司每月服务用户门店数超过15000+</label>
            </div>
            <div id="div-content">
                <div class="div-content-item" v-for="(item, index) in dataLists" :key="index"
                     :style="{ marginBottom: item.mgBottom + 'vmin' }">
                    <div class="div-content-gray">
                        <div class="div-text-item" v-for="(texItem, index1)  in item.textItem" :key="index1">
                            <label class="text-red"
                                   style="color: red;font-size: 2vmin;height: ">{{ texItem.title }}</label>
                            <label class="text-bk"
                                   style="color: #000000;font-size: 2vmin;margin-top: 3px">{{ texItem.des }}</label>
                        </div>
                    </div>
                    <div class="div-year-item">
                        <label class="text-bk">{{ item.year }}</label>
                        <label class="text-red">{{ item.des }}</label>
                    </div>
                    <div>
                        <div class="div-line" :style="{height:item.lineHeight+'vh'}"></div>
                    </div>
                    <div>
                        <img class="img-yuan" src="@/assets/imgs/ic_develop_yuanhuang.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {ref} from "vue"

let dataLists = ref([
    {
        textItem: [{
            title: "服务能力建设",
            des: "组建东莞区域工程师团队建立覆盖广东的服务网络"
        }, {
            title: "联想/戴尔/IBM/官方售后",
            des: "服务覆盖PC全系产品（笔记本，台式机，POS，打印、手持终端等）"
        }],
        year: "2011年",
        des: "组建IT服务团队",
        lineHeight: 8,
        mgBottom: 1.5
    },
    {
        textItem: [{
            title: "企业IT能力打造",
            des: "推出一站式IT服务方案建立工程师培养体系"
        }, {
            title: "签约众多IT服务项目",
            des: "美宜佳、麦当劳、康师傅等东莞海关、沃尔玛、家乐福"
        }],
        year: "2011-2017",
        des: "全面企业运维服务",
        lineHeight: 18,
        mgBottom: 4
    },
    {
        textItem: [{
            title: "公司成立",
            des: "通过零售行业标杆服务建立自有服务体系"
        }, {
            title: "多品牌业务",
            des: "广东省、广西省服务团队全覆盖\n" +
                "多品牌运维服务解决方案"
        }],
        year: "2017-2019",
        des: "团队稳定，多品牌服务",
        lineHeight: 22,
        mgBottom: 13
    },
    {
        textItem: [{
            title: "全国本地化服务",
            des: "华东、华北、华中、西南区域工程师本地化服务"
        }, {
            title: "多场景IT服务能力",
            des: "覆盖厂商、企业、消费者布局智慧办公、教育、餐饮、零售等行业"
        }, {
            title: "合作共赢",
            des: "联想、浪潮、DELL、美宜佳、麦当劳、乐比鲜、商米COCO、商慧天下、勤冠启明星辰、特美声"
        }],
        year: "2020-2022",
        des: "全国服务团队",
        lineHeight: 8,
        mgBottom: 31
    }
]);

</script>

<style scoped>
#div-root {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    position: relative;
}

#div-left {
    width: 25vw;
    height: 100vh;
    padding-top: 40px;
    position: absolute;
    z-index: 10;
}

#div-left-kuai {
    width: 15vw;
    height: 16vh;
    background-color: #ED7D31;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vmin;
    color: white;
}

#div-right {
    width: 100vw;
    height: 100vh;
    position: relative;
}

#div-right #div-bottom {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: end;
    align-items: end;
}

#div-right #div-bottom #img-bg {
    width: 75vw;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-right: 5vw;
}

#div-right #div-top {
    position: absolute;
    z-index: 999;
    padding-top: 4vh;
}

#div-right #div-top label {
    font-size: 3.5vmin;
    color: red;
    margin-left: 20vw;
}

#div-right #div-content {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: end;
    align-items: end;
}

.div-content-item {
    width: 20vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2vw;
}

.div-content-gray {
    width: 17vw;
    background-color: #D4E2EC;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
}

.div-text-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 10px;
}

.div-year-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2px;
}

.div-year-item .text-bk {
    font-size: 2vmin;
    color: black;
}

.div-year-item .text-red {
    font-size: 2vmin;
    color: #FFBD44;
}

.div-line {
    width: 0.6vmin;
    height: 8vmin;
    background-color: #FFBD44;
    margin-top: 2px;
}

.img-yuan {
    width: 4vmin;
    height: 4vmin;
}

</style>